import {
    IonButton,
    IonButtons,
    IonCol,
    IonHeader,
    IonIcon,
    IonLabel,
    IonMenuButton,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';


import './Header.css';
import { useHistory, useLocation } from 'react-router';

interface AppPage {
    url: string;
    title: string;
    showWhen: string;
    icon: string;
}

const appPages: AppPage[] = [
    {
        title: 'Home',
        url: '/',
        showWhen: 'recognizedUser',
        icon: './assets/icon/home.svg'
    },
    {
        title: 'Settings',
        url: '/settings',
        showWhen: 'recognizedUser',
        icon: './assets/icon/settings.svg'
    },
    {
        title: 'Help',
        url: '/help',
        showWhen: 'recognizedUser',
        icon: './assets/icon/help.svg'
    }
];

type HeaderProps = {
    type?: 'primary' | 'secondary'
}

const Header: React.FC<HeaderProps> = (headerProps) => {

    const location = useLocation();
    const history = useHistory();

    return (
        <>
            <IonHeader id='mobile-header' className="ion-no-border" color='primary'>
                <IonToolbar className='ion-text-center'>
                    <IonTitle onClick={() => history.push("/")}>DeFi - Remit</IonTitle>
                    <IonButtons slot="start">
                        {
                            headerProps && headerProps.type === 'secondary' ?
                                <IonButton aria-label='back' fill='clear' onClick={() => history.goBack()}>
                                    <IonIcon icon='./assets/icon/back.svg' size='large' />
                                </IonButton>
                                :
                                <IonMenuButton aria-label='menu'>
                                    <IonIcon icon='./assets/icon/menu.svg' size='large' />
                                </IonMenuButton>
                        }
                    </IonButtons>
                    {
                        !(headerProps && headerProps.type === 'secondary') && <IonButtons slot="end">
                            <IonButton aria-label='transaction history' fill='clear' onClick={() => history.push("/transactions")}>
                                <IonIcon icon='./assets/icon/transactions.svg' size='large' />
                            </IonButton>
                        </IonButtons>
                    }

                </IonToolbar>
            </IonHeader>
            <IonHeader id='desktop-header' className="ion-no-border">
                <IonRow className='ion-text-center ion-no-padding'>
                    <IonCol sizeMd='3' sizeLg='3' className='ion-text-right'>
                        <IonButton
                            fill='clear' routerLink={"/"} routerDirection="none" >
                            <IonIcon src="./assets/icon/logo.svg" size='large' />
                            <IonLabel>DeFi - Remit</IonLabel>
                        </IonButton>
                    </IonCol>
                    <IonCol sizeMd='6' sizeLg='6'>
                        <IonRow>
                            {appPages.map((appPage, index) => {
                                return (
                                    <IonCol key={index} size="4" className='ion-no-padding'>
                                        <IonButton className={location.pathname === appPage.url ? 'selected' : ''}
                                            fill='clear' routerLink={appPage.url} routerDirection="none" >
                                            <IonIcon slot="start" size='small' src={appPage.icon} />
                                            <IonLabel>{appPage.title}</IonLabel>
                                        </IonButton>
                                    </IonCol>
                                )
                            })
                            }
                        </IonRow>
                    </IonCol>
                    <IonCol sizeMd='3' sizeLg='3' className='ion-text-left'>
                        <IonButton fill='clear' onClick={() => history.push("/transactions")}>
                            <IonIcon icon='./assets/icon/transactions.svg' size='large' />
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonHeader>
        </>
    );
};

export default Header;