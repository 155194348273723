import React from 'react';
import { IonApp, IonContent, IonRow, IonImg, IonFooter, IonToolbar, IonTitle, IonSpinner, IonHeader, IonProgressBar } from '@ionic/react';
import './Loading.css';

interface LoadingProps {
}

const Loading: React.FC<LoadingProps> = (loadingProps) => {
    return (
        <IonApp>
            <IonContent className="ion-padding">
                <IonRow className='splash-screen-logo'>
                    <IonImg className='logo' src='./assets/icon/logo.svg'></IonImg>
                </IonRow>
            </IonContent>
            <IonHeader>
                <IonToolbar className='ion-text-center title'>
                    <IonTitle>DeFi - Remit</IonTitle>
                    <IonProgressBar type="indeterminate"></IonProgressBar>
                </IonToolbar>
            </IonHeader>
        </IonApp>
    );
};

export default Loading;
