import {
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonTitle,
    IonToolbar,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';

interface AppPage {
    url: string;
    title: string;
    showWhen: string;
    icon: string;
}

const appPages: AppPage[] = [
    {
        title: 'Home',
        url: '/',
        showWhen: 'recognizedUser',
        icon: './assets/icon/home.svg'
    },
    {
        title: 'Settings',
        url: '/settings',
        showWhen: 'recognizedUser',
        icon: './assets/icon/settings.svg'
    },
    {
        title: 'Help',
        url: '/help',
        showWhen: 'recognizedUser',
        icon: './assets/icon/help.svg'
    }
];

const Menu: React.FC = () => {
    const location = useLocation();

    return (
        <IonMenu id="mobile-side-menu" contentId="main" type="overlay">
            <IonHeader>
                <IonToolbar className='ion-text-center title'>
                    <IonTitle>DeFi - Remit</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList id="how-2-guru-menu-heading">
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem className={location.pathname === appPage.url ? 'selected' : ''}
                                    routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                    <IonIcon color='success' slot="start"
                                        src={appPage.icon}
                                        className={"big"} />
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;