import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../../components/ExploreContainer';
import './Info.css';
import Header from '../../components/header/Header';

const Info: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Header />
        <>
          Help content here.
        </>
      </IonContent>
    </IonPage>
  );
};

export default Info;
