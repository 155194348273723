//https://dev.to/dchowitz/react-firebase-a-simple-context-based-authentication-provider-1ool
import { Firestore, getFirestore } from '@firebase/firestore';
import { Functions, getFunctions } from '@firebase/functions';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth, User } from 'firebase/auth';
import { FirebaseStorage, getStorage } from "firebase/storage";
import { connectFirestoreEmulator, doc, initializeFirestore, onSnapshot } from 'firebase/firestore';
import { ProfileStatus } from "../../defi-remit-apis/src/types/ProfileStatus";
import React from 'react';
import { connectFunctionsEmulator } from 'firebase/functions';

type ContextState = {
    isLoaded: boolean,
    auth: Auth,
    functions: Functions,
    db: Firestore,
    storage: FirebaseStorage
    user: User | undefined
    profileStatus: ProfileStatus | undefined | null
}

interface Props {
    children: React.ReactNode;
}

const FirebaseContext = React.createContext<ContextState | undefined>(undefined);

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const FirebaseProvider: React.FC<Props> = ({ children }) => {
    const app: FirebaseApp = initializeApp(firebaseConfig);

    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<User | undefined>(undefined);
    const [profileStatus, setProfileStatus] = React.useState<ProfileStatus | undefined | null>(undefined);

    const auth = getAuth(app);
    // Updating locations.
    const functions = getFunctions(app, "asia-south1");
    //const functions = getFunctions(app);

    initializeFirestore(app, {
        ignoreUndefinedProperties: true,

        // https://stackoverflow.com/questions/71045643/could-not-reach-cloud-firestore-backend-react-native-firebase-v9
        experimentalForceLongPolling: true
    });

    const db = getFirestore(app);

    const storage = getStorage(app);

    const value = { auth, functions, db, user, profileStatus, storage, isLoaded };

    React.useEffect(() => {
        // connectFunctionsEmulator(functions, "0.0.0.0", 5001);
        // connectFirestoreEmulator(db, "127.0.0.1", 8080);
        // connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true });

        const unsubscribe = auth.onAuthStateChanged(function (user) {

            setIsLoaded(true);

            if (user) {
                console.log(user);
                setUser(user);

                onSnapshot(doc(db, "profile_statuses", user.uid), (doc) => {
                    console.log(doc.data());
                    doc.data() ?
                        setProfileStatus(doc.data() as ProfileStatus) : setProfileStatus(null);
                });
            } else {
                // user signed out
                setUser(undefined);
            }
        }, function (error) {
            console.log(error);
        });
        return unsubscribe;
    }, [])

    return (
        <FirebaseContext.Provider value={value}>
            {children}
        </FirebaseContext.Provider>
    );
};

function useFirebase() {
    const context = React.useContext(FirebaseContext);
    if (context === undefined) {
        throw new Error("useFirebaseAuth must be used within a FirebaseAuthProvider");
    }
    return context;
}

export { FirebaseProvider, useFirebase };