import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Info from './pages/info/Info';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useFirebase } from './context/FirebaseAuthContext';
import './App.css'
import Menu from './components/menu/Menu';
import { lazy } from 'react';
import Loading from './components/splash/Loading';
import AutoAuthPage from './pages/auth/AutoAuthPage';

setupIonicReact(
  {
    swipeBackEnabled: false,
  }
);

const WalletPage = lazy(() => import('./pages/wallet/WalletPage'));
const ContactUsPage = lazy(() => import('./pages/contact/ContactUsPage'));
const BankAccountsPage = lazy(() => import('./pages/banks/BankAccountsPage'));
const Transactions = lazy(() => import('./pages/transactions/Transactions'));
const Transfer = lazy(() => import('./pages/transfer/Transfer'));
const KYC = lazy(() => import('./pages/enroll/KYC'));
const IndexPage = lazy(() => import('./pages/intro/Index'));
const Settings = lazy(() => import('./pages/settings/Settings'));
const AuthPage = lazy(() => import('./pages/auth/AuthPage'));

const App: React.FC = () => {

  const firebase = useFirebase();

  // Context loaded.
  if (firebase.isLoaded && firebase.auth) {
    return (

      <IonApp>
        <IonReactRouter>
          <Menu />
          <Suspense fallback={<Loading />}>
            <IonRouterOutlet id="main" animated={false}>
              <Route exact={true} path="/transfer" component={Transfer} />
              <Route exact={true} path="/">
                <IndexPage />
              </Route>
              <Route exact={true} path="/info">
                <Info />
              </Route>
              <Route exact={true} path="/settings">
                <Settings />
              </Route>
              <Route exact={true} path="/kyc">
                <KYC />
              </Route>
              <Route exact={true} path="/transactions">
                <Transactions />
              </Route>
              <Route exact={true} path="/bank-accounts">
                <BankAccountsPage />
              </Route>
              <Route exact={true} path="/help">
                <ContactUsPage />
              </Route>
              <Route exact={true} path="/wallets">
                <WalletPage />
              </Route>
              <Route exact={true} path="/auto-auth">
                <AutoAuthPage />
              </Route>
              <Route exact={true} path="/auth">
                <AuthPage />
              </Route>
            </IonRouterOutlet>
          </Suspense>
        </IonReactRouter>
      </IonApp>
    )
  } else {
    // Loading screen
    return (<Loading />)
  }
};

export default App;
