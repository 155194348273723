import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../context/FirebaseAuthContext';

import { useHistory } from 'react-router';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { IonPage, IonContent, useIonLoading, IonCol, IonGrid, IonRow, useIonToast } from '@ionic/react';
import Header from '../../components/header/Header';
import Loading from '../../components/splash/Loading';

interface AutoAuthPageProps {
}

const AutoAuthPage: React.FC<AutoAuthPageProps> = (autoAuthPageProps) => {

    const firebase = useFirebase();
    const history = useHistory();
    const search = window.location.search;

    const auth = getAuth();
    let [errorMessage, setErrorMessage] = useState<string>("");
    const [present, dismiss] = useIonLoading();

    const [toast] = useIonToast();

    const presentToast = (message: string, color: string) => {
        toast({
            message: message,
            duration: 4000,
            position: 'bottom',
            color: color
        });
    };

    useEffect(() => {
        const params = new URLSearchParams(search);

        let authToken = params.get('token');

        if (authToken) {
            autoSignIn(authToken);
        }
    }, []);

    let autoSignIn = function (autoAuthToken: string) {
        present({
            message: 'Authenticating your account',
            spinner: 'lines'
        });

        let verifyAutoAuthToken = httpsCallable(firebase.functions, 'verifyAutoAuthToken');
        verifyAutoAuthToken({ autoAuthToken: autoAuthToken })
            .then((result: any) => {

                if (result.data === null) {
                    //setErrorMessage("Trouble signing in your account, please check your email and otp");
                    // presentToast("OTP Verification failed. Please try again", 'danger');
                    return;
                }

                if (result.data.verified) {
                    signInWithCustomToken(auth, result!.data!.authToken)
                        .then((data) => {
                            presentToast("Signed in successfully", 'success');
                            window.location.href = result.data.sourceUrl;
                        })
                        .catch((error) => {
                            setErrorMessage("Trouble signing in your account, please check your email and otp");
                            presentToast("Failed signing in. Please try again", 'danger');
                        })
                } else {
                    setErrorMessage("Your account is not verified, please verify your account");
                    presentToast("Failed signing in. Please try again", 'danger');
                    window.location.href = "/";
                }
            })
            .catch((error) => {
                presentToast("Failed signing in. Please try again", 'danger');
                window.location.href = "/";
            })
            .finally(() => {
                dismiss()
            });
    }

    return (
        <Loading />
    );

}

export default AutoAuthPage;
